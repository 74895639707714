exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-access-index-jsx": () => import("./../../../src/pages/access/index.jsx" /* webpackChunkName: "component---src-pages-access-index-jsx" */),
  "component---src-pages-clinic-greetings-index-jsx": () => import("./../../../src/pages/clinic/greetings/index.jsx" /* webpackChunkName: "component---src-pages-clinic-greetings-index-jsx" */),
  "component---src-pages-clinic-index-jsx": () => import("./../../../src/pages/clinic/index.jsx" /* webpackChunkName: "component---src-pages-clinic-index-jsx" */),
  "component---src-pages-cooperation-index-jsx": () => import("./../../../src/pages/cooperation/index.jsx" /* webpackChunkName: "component---src-pages-cooperation-index-jsx" */),
  "component---src-pages-draft-jsx": () => import("./../../../src/pages/draft.jsx" /* webpackChunkName: "component---src-pages-draft-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-medical-fever-index-jsx": () => import("./../../../src/pages/medical/fever/index.jsx" /* webpackChunkName: "component---src-pages-medical-fever-index-jsx" */),
  "component---src-pages-medical-homecare-index-jsx": () => import("./../../../src/pages/medical/homecare/index.jsx" /* webpackChunkName: "component---src-pages-medical-homecare-index-jsx" */),
  "component---src-pages-medical-index-jsx": () => import("./../../../src/pages/medical/index.jsx" /* webpackChunkName: "component---src-pages-medical-index-jsx" */),
  "component---src-pages-medical-internal-index-jsx": () => import("./../../../src/pages/medical/internal/index.jsx" /* webpackChunkName: "component---src-pages-medical-internal-index-jsx" */),
  "component---src-pages-medical-online-index-jsx": () => import("./../../../src/pages/medical/online/index.jsx" /* webpackChunkName: "component---src-pages-medical-online-index-jsx" */),
  "component---src-pages-medical-pcr-index-jsx": () => import("./../../../src/pages/medical/pcr/index.jsx" /* webpackChunkName: "component---src-pages-medical-pcr-index-jsx" */),
  "component---src-template-blog-jsx": () => import("./../../../src/template/blog.jsx" /* webpackChunkName: "component---src-template-blog-jsx" */),
  "component---src-template-blog-post-jsx": () => import("./../../../src/template/blogPost.jsx" /* webpackChunkName: "component---src-template-blog-post-jsx" */),
  "component---src-template-category-jsx": () => import("./../../../src/template/category.jsx" /* webpackChunkName: "component---src-template-category-jsx" */),
  "component---src-template-news-jsx": () => import("./../../../src/template/news.jsx" /* webpackChunkName: "component---src-template-news-jsx" */),
  "component---src-template-news-post-jsx": () => import("./../../../src/template/newsPost.jsx" /* webpackChunkName: "component---src-template-news-post-jsx" */)
}

